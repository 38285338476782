import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9d11110 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _609b9a0a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _002ea08e = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _648930a8 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _3768a578 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _6ad1c383 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _1b7ce786 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _19dbca92 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _6ed05931 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _0b80e468 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _082985f0 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _c100cfdc = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _2847659b = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _0c6e7b01 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _65480b82 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _26ea3bbe = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _1947fa44 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _4cf25f4d = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _ffaace50 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _5f87554d = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _35c044ac = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _79962f3c = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _04befcab = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _a7f5626a = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _5aaad45c = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _d218121a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _691b223b = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _0deac35e = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _058aa4c0 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _e9d11110,
    name: "check-email"
  }, {
    path: "/login",
    component: _609b9a0a,
    name: "login"
  }, {
    path: "/logout",
    component: _002ea08e,
    name: "logout"
  }, {
    path: "/medications",
    component: _648930a8,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _3768a578,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _6ad1c383,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _1b7ce786,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _19dbca92,
    name: "register"
  }, {
    path: "/registered",
    component: _6ed05931,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _0b80e468,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _082985f0,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _c100cfdc,
    name: "settings",
    children: [{
      path: "close-account",
      component: _2847659b,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _0c6e7b01,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _65480b82,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _26ea3bbe,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _1947fa44,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _4cf25f4d,
      name: "settings-security"
    }, {
      path: "user",
      component: _ffaace50,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _5f87554d,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _35c044ac,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _79962f3c,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _04befcab,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _a7f5626a,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _5aaad45c,
    name: "redirect-new"
  }, {
    path: "/",
    component: _d218121a,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _691b223b,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _0deac35e,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _058aa4c0,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
